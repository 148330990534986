
import { Link } from "gatsby"
import React from "react"
import tw from "tailwind.macro"
import styled from 'styled-components'

const STile = styled(Link)`
  background: white;
  overflow: hidden;
  ${tw`text-text`}
  ${tw`rounded-md`}
  transition: all 0.1s ease-in-out 0s;
  &:hover {
    ${tw`text-text`}
    ${tw`shadow-xl`}
  }
  img, .img-placeholder  {
    transition: all 0.4s ease-in-out 0s !important;
  }
  &:hover {
    img, .img-placeholder  { 
      transform: scale(1.05);
    }
  }
`

const PostTile = (props) => {
  const { post } = props;

  return (
    <STile key={post.node.id} to={`/blog${post.node.fields.slug}`} className="hover:scale-50">
        <div>
          { post.node.frontmatter.thumbnail &&
            <img alt={post.node.frontmatter.title} src={post.node.frontmatter.thumbnail}/>}
          { !post.node.frontmatter.thumbnail &&
            <div className="relative">
              <div className="absolute text-white text-2xl font-bold items-center justify-center p-10 z-10">{post.node.frontmatter.title}</div>
              <img alt={post.node.frontmatter.title} src="/images/uploads/blog-template_simplebackups.png" className="z-0"/>
            </div>
          }
        </div>
        <div className="p-6">
          <h2 className="text-2xl mb-0 font-bold">{post.node.frontmatter.title}</h2>
          {post.node.frontmatter.lastUpdateDate ? 
            <p className="text-sm text-gray-500 mt-2">{post.node.frontmatter.lastUpdateDate}</p>:
            <p className="text-sm text-gray-500 mt-2">{post.node.frontmatter.date}</p>
          }
          <p
            className="mt-4 text-base leading-relaxed"
            dangerouslySetInnerHTML={{__html: post.node.excerpt}}
          />
        </div>
    </STile>
  )
}

export default PostTile;
