import React from "react"
import { Button } from 'components/ui/button';
import * as Ui from "components/ui"

const PostTile = (props) => {
  const { post } = props;
  return (
    <div key={post.node.id} to={`/blog${post.node.fields.slug}`} className="md:flex ">
      <div className="md:visible md:w-3/5 md:mr-12">
        { post.node.frontmatter.thumbnail && <img alt={post.node.frontmatter.title} src={post.node.frontmatter.thumbnail} className="rounded-lg"/> }
        { !post.node.frontmatter.thumbnail &&
          <div className="relative rounded-lg">
            <div className="absolute text-white text-2xl leading-tight md:text-4xl font-bold items-center justify-center p-20 z-10">{post.node.frontmatter.title}</div>
            <img alt={post.node.frontmatter.title} src="/images/uploads/blog-template_simplebackups.png" className="z-0 rounded-lg"/>
          </div>}
      </div>

      <div className="md:w-2/5">
        <p className="text-primary uppercase font-bold text-base mb-4 mt-4">Latest Post</p>
        <Ui.Typography tag="h2" className="text-2xl md:text-4xl leading-tight mb-0 font-bold">{post.node.frontmatter.title}</Ui.Typography>
        <p
          className="mt-6 text-base leading-relaxed"
          dangerouslySetInnerHTML={{__html: post.node.excerpt}}
        />
        <div className="mt-6">
          <Button href={`/blog${post.node.fields.slug}`} tag="a" size="medium" color="primary">
            Read more <span>→</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PostTile;
