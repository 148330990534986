import * as Ui from "components/ui";
import * as Sections from "components/sections";
import * as Layout from "components/layout";
import { Section, Container, Row, Typography } from "components/ui";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { PostTile, FeaturedPostTile } from "components/blog";
import { theme } from "../../tailwind.config";
import { getTagMeta } from "../configs/blog-tags";

const BlogList = (props) => {
  const { data } = props;
  const { currentPage, numPages, subTags, parentTag, tag } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? "/blog/" + tag
      : "/blog/" + tag + "/" + (currentPage - 1).toString() + "/";
  const nextPage = "/blog/" + tag + "/" + (currentPage + 1).toString() + "/";
  const maxPages = 5;

  let metaPage = currentPage > 1 ? "(" + currentPage + ")" : "";
  return (
    <Layout.Layout>
      <Layout.SEO
        title={`SimpleBackups Blog "${getTagMeta(tag).name}" ${metaPage}`}
        description={`Find out about ${
          getTagMeta(tag).name
        } in our blog! New releases, guides and how-tos to help you manage your server, database, storage and application backups. ${metaPage}`}
      />
      <Layout.HeroEmpty />
      <Ui.Section>
        <Ui.Container>
          <div className="row mt-12">
            <div className="col-md-12">
              <div className="text-center md:mb-16">
                <p className="text-sm text-gray-500 mb-6">
                  <a href="/blog">Blog</a>
                  {parentTag && (
                    <>
                      <span className="text-base mx-2">/</span>
                      <a href={`/blog/${parentTag}`}>
                        {getTagMeta(parentTag).name}
                      </a>{" "}
                    </>
                  )}
                  <span className="text-base mx-2">/</span>
                  <a href={`/blog/${tag}`}>{getTagMeta(tag).name}</a>
                </p>
                <h1 className="font-heading tracking-tight text-5xl md:text-6xl md:leading-tight font-black">
                  {getTagMeta(tag).name}
                </h1>
                <div className="flex gap-2 md:gap-4 items-center justify-center mt-8 flex-wrap">
                  {subTags.map((subTag) => (
                    <a
                      href={`/blog/${subTag}`}
                      className="px-6 py-2 bg-gray-50 rounded-lg hover:text-primary hover:shadow-lg hover:translate-y-2 transition block font-medium text-sm"
                    >
                      {getTagMeta(subTag).name.length === 0
                        ? "All"
                        : getTagMeta(subTag).name}
                    </a>
                  ))}
                </div>
              </div>
              <div className="mt-12 md:mt-32 mb-16 md:mb-32">
                <FeaturedPostTile
                  post={data.lastPost.edges[0]}
                ></FeaturedPostTile>
              </div>
            </div>
          </div>
        </Ui.Container>
      </Ui.Section>

      <Ui.Section className="bg-gray-50">
        <Ui.Container>
          <Ui.Row>
            <div className="grid md:grid-cols-3 gap-8">
              {data.posts.edges.map((post) => {
                return <PostTile post={post}></PostTile>;
              })}
            </div>

            <ul
              className="mt-12"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
                listStyle: "none",
                padding: 0,
              }}
            >
              {!isFirst && (
                <Link to={prevPage} rel="prev" className="mr-2 text-base">
                  ← Previous Page
                </Link>
              )}
              {Array.from({ length: numPages }, (_, i) =>
                i > maxPages ? null : (
                  <li
                    key={`pagination-number${i + 1}`}
                    style={{
                      margin: 0,
                    }}
                  >
                    <Link
                      to={`/blog/${tag}${i === 0 ? "" : "/" + (i + 1)}/`}
                      className="bg-white hover:bg-primary hover:text-white text-base text-gray-800 font-medium py-2 px-4 rounded mr-1 transition"
                    >
                      {i + 1}
                    </Link>
                  </li>
                )
              )}
              {!isLast && numPages > 1 && (
                <Link to={nextPage} rel="next" className="ml-2 text-base">
                  Next Page →
                </Link>
              )}
            </ul>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>
    </Layout.Layout>
  );
};

export const pageQuery = graphql`
  query BlogTagQuery($skip: Int!, $limit: Int!, $tagRegex: String) {
    posts: allMdx(
      filter: {
        frontmatter: {
          layout: { in: ["blog", "article"] }
          tags: { regex: $tagRegex }
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            thumbnail
            date(formatString: "MMMM D, YYYY")
            lastUpdateDate(formatString: "MMMM D, YYYY")
          }
        }
      }
    }
    lastPost: allMdx(
      filter: {
        frontmatter: {
          layout: { in: ["blog", "article"] }
          tags: { regex: $tagRegex }
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 1
      skip: 0
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            thumbnail
            date(formatString: "MMMM D, YYYY")
            lastUpdateDate(formatString: "MMMM D, YYYY")
          }
        }
      }
    }
  }
`;

export default BlogList;
