export const tags = [
  {
    id: 'using-simplebackups',
    name: 'Using SimpleBackups',
    description: '',
  },
  {
    id: 'mysql',
    name: 'MySQL',
    description: '',
  },
  {
    id: 'postgresql',
    name: 'PostgreSQL',
    description: '',
  },
  {
    id: 'digitalocean',
    name: 'DigitalOcean',
    description: '',
  },
  {
    id: 'mongodb',
    name: 'MongoDB',
    description: '',
  },
  {
    id: 'storage-replication',
    name: 'Storage Replication',
    description: '',
  },
  {
    id: 'release-notes',
    name: 'Release notes',
    description: '',
  },
]

export const getTagMeta = (id) => {
  id = (id.includes('/')) ? id.split('/')[1] : id;
  let meta = tags.find((tag) => tag.id === id);

  if (!meta) {
    meta = {id: id, name: capitalizeFirstLetter(id), description: ''};
  }

  return meta;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}